import React, { Component } from 'react'
import { MdVolumeOff, MdVolumeUp } from 'react-icons/all'
import Sound from 'react-sound'

export default class MusicTool extends Component {
    constructor() {
        super();
        var sourceControl = localStorage.getItem("source-control-on");
        this.state = {
            soundOn: sourceControl || !!sourceControl
        };
    }

    toggleSound() {
        this.setState({ soundOn: !this.state.soundOn });
        localStorage.setItem("source-control-on", this.state.soundOn);
    }
    render() {
        console.log("play s : ", this.props.play);
        console.log("source s : ", this.state.soundOn);

        var playable = this.props.play && this.state.soundOn ?
        Sound.status.PLAYING : Sound.status.PAUSED;
        return (
            <div className="tool-item" id="tool-music">
                <div className="item-icon" onClick={this.toggleSound.bind(this)}>
                    {playable == Sound.status.PLAYING ? <MdVolumeUp /> : <MdVolumeOff />}
                    <Sound
                        url={"/resources/background_music.mp3"}
                        playStatus={playable}
                        loop={true}
                        autoLoad={true}
                        volume={20}
                    />
                </div>
            </div>
        )
    }
}
