import {
    NavigationModel,
    action_types
} from './model'

const initState = {
    titleName: "Lạc Hồng Viên",
    currentItemId: 0,
};

export default function handler(state = new NavigationModel(initState), actions) {
    if (actions.type === action_types.CHANGE_NAV_STATUS) {
        return new NavigationModel({
            ...state,
            isCollapseNav: actions.payload
        });
    }
    if (actions.type === action_types.CHANGE_SELECT_ITEM) {
        var watchedItems = state.watchedItems;
        if (!watchedItems.includes(actions.payload.id))
            watchedItems.push(actions.payload.id);

        return Object.assign(state, {
            currentItemId: actions.payload.id,
            watchedItems,
            navSubItem: actions.payload.navItems
        })
    }

    if (actions.type === action_types.CHANGE_NEST_MODE) {
        return new NavigationModel({
            ...state,
            isNestList: actions.payload
        });
    }

    return state;
}