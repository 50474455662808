import React, { Component } from 'react'
import { supported_data_type } from './model';
import './style.css'
import './style_list.css'
import './style_text.css'
import './style.mobile.css'
import ReactMarkdown from "react-markdown"

export default class DataViewer extends Component {

    getViewByType() {

        var { data, dataType, onItemClick } = this.props;
        if (dataType === supported_data_type.MARKDOWN) {
            return <ReactMarkdown className="content-styletext" source={data} />
        }
        if (dataType === supported_data_type.HTML) {
            return <div dangerouslySetInnerHTML={{ _html: data }} />
        }
        if (dataType === supported_data_type.ELEMENT) {
            var TargetElement = data;
            return <div className="element-viewer">{<TargetElement {...this.props} />}</div>
        }

        if (dataType === supported_data_type.IMAGES) {
            var imageView = data.map(v => {
                return <div className="image-item" onClick={()=>onItemClick(v)}>
                    <img src={v.src} />
                </div>
            });
            return <div className="image-viewer">
                {imageView}
            </div>
        }

        if (dataType === supported_data_type.LIST) {
            var listItemView = data.map(v=>{
                return <div className="list-item" onClick={()=>onItemClick(v)}>
                    <div className="item-title">{v.title}</div>
                    <div className="item-description">{v.description}</div>
                </div>
            })
            return <div className="list-viewer">
                {listItemView}
            </div>
        }
    }

    render() {
        return (
            <div className="content-viewer">
                {this.getViewByType.call(this)}
            </div>
        )
    }
}
