import React, { Component } from 'react'
import './style.css'
import './style.mobile.css'
import { MdSkipNext } from 'react-icons/md'
import ReactPlayer from 'react-player'


export default class Introduction extends Component {
    constructor() {
        super();
        this.state = {
            playIntro: true,
            videoPlay: false
        }
    }

    skipVideo() {
        this.setState({ playIntro: false });
        this.props.onIntroComplete(this);
    }

    componentDidMount() {
        // setTimeout(()=>{
        //     this.refs.guide.click();
        // }, 3000);
    }

    getIntroVideoView() {
        var guideStatus = this.state.videoPlay ? " guide-status-hidden" : "";
        return <div className="introduction">
            <div ref="guide" className={"guide-list" + guideStatus} onClick={() => { this.setState({ videoPlay: true }) }}>
                <div className="guide-item">
                    <div className="item-img">
                        <img src="/resources/009-tap-8.svg" />
                    </div>
                    <div className="item-title">
                        {"Click vào màn hình để bắt đầu hoặc sử dụng các tính năng"}
                    </div>
                </div>
                {/* <div className="guide-item">
                    <div className="item-img">
                        <img src="/resources/005-zoom-in-1.svg" />
                    </div>
                    <div className="item-title">
                        {"Vuốt 2 tay trên màn hình để thu phóng ảnh"}
                    </div>
                </div> */}
            </div>
            <div className="intro-video">
                <ReactPlayer
                    url="https://media.vsmap.vn/medialachongvien/introduction.mp4"
                    playing={this.state.videoPlay}
                    width={"100%"}
                    height={"auto"}
                    onEnded={this.skipVideo.bind(this)} />
            </div>
            <div className="skip-btn" onClick={this.skipVideo.bind(this)}>
                <div className="btn-title">Bỏ qua</div>
                <div className="btn-icon">
                    <MdSkipNext />
                </div>
            </div>
        </div>
    }

    render() {
        return (this.state.playIntro & !this.props.skip ?
            this.getIntroVideoView.call(this) :
            this.props.onIntroComplete(this));
    }
}
