import { action_types } from './model'
import queryString from 'querystring'

var query = queryString.parse(window.location.search.substr(1));
console.log("localtion", query);

const initState = {
    language: query.lang || localStorage.getItem("language"),
    isAutoRotate: (query.rotateOn || localStorage.getItem("isAutoRotate")) == "true",
    isGyroOn: (query.gyroOn || localStorage.getItem("isGyroOn")) == "true",
    // isSubOn: (query.subOn || localStorage.getItem("isSubOn")) == "true",
    isWidescreenOn: (query.simple || localStorage.getItem("isWidescreenOn")) == "true"
};

export default function handler(state = initState, actions) {
    if (actions.type === action_types.CHANGE_SETTING_VALUE) {
        return Object.assign(state, actions.payload);
    }
    return state;
}
