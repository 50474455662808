import React, { Component } from 'react'
import { FaPlus, FaMinus } from "react-icons/fa"
import DataViewer from '../data-viewer/container'
import './style.css'
import './style.mobile.css'

export default class MapViewer extends Component {
    constructor() {
        super();
    }

    getMainContentView() {
        var { content, tabIndex } = this.props;
        var currentViewData = content[tabIndex] || {};

        var mapView;

        if (currentViewData.type == 'map') {
            mapView = <iframe src={currentViewData.data} scrolling="no" />;
        }

        return <div className="main-viewer">
            <div className="controler">
                {/* <div className="zoom-actions">
                    <FaMinus />
                    <FaPlus />
                </div> */}
                <div className="mode-actions">

                </div>
            </div>
            <div className="map-viewer">
                {mapView}
            </div>
        </div>
    }

    getExtendedView() {
        var { content, tabIndex, changeMapView } = this.props;
        var currentViewData = content[tabIndex];
        var extendMapView = currentViewData.extended || {};

        return <div className="additional-viewer">
            <div className="title">
                {extendMapView.title}
            </div>
            <div className="data">
                <DataViewer
                    data={extendMapView.data}
                    dataType={extendMapView.type}
                    onItemClick={changeMapView} />
            </div>
        </div>
    }

    render() {
        if (this.props.minimize === true) {
            return (
                <div className="map-container minimize">
                    {this.getMainContentView.call(this)}
                </div>
            )
        }

        return (
            <div className="map-container">
                {this.getMainContentView.call(this)}
                {this.getExtendedView.call(this)}
            </div>
        )
    }
}
