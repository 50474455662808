import {PopupModel, action_types} from './model'

export default function handler(state = new PopupModel(), actions) {
    if (actions.type === action_types.LOAD_POPUP_CONTENT) {
        return new PopupModel(actions.payload);
    }
    if (actions.type === action_types.EXIT) {
        return new PopupModel(actions.payload);
    }
    if (actions.type === action_types.TRIGGER_UPDATE_POPUP) {
        return new PopupModel(state);
    }
    return state;
}