import React, { Component } from 'react'
import {MdLanguage, MdRotateRight, MdScreenLockRotation, MdSubject, MdPanoramaWideAngle} from 'react-icons/all'
import Switch from "react-switch";
import './style.css'
import './style.mobile.css'

export default class Setting extends Component {
    constructor(){
        super();
        this.settingItems = [
            {
                key: "language",
                icon: MdLanguage,
                title: "Ngôn ngữ",
                type: "select",
                options: {
                    // en: "English",
                    vi: "Tiếng Việt"
                }
            },
            {
                key: "isAutoRotate",
                icon: MdRotateRight,
                title: "Tự động xoay",
                type: "switch",
            },
            {
                key: "isGyroOn",
                icon: MdScreenLockRotation,
                title: "Con quay hồi chuyển",
                type: "switch",
            },
            // {
            //     key: "isSubOn",
            //     icon: MdSubject,
            //     title: "Hiển thị phụ đề",
            //     type: "switch",
            // },
            {
                key: "isWidescreenOn",
                icon: MdPanoramaWideAngle,
                title: "Chế độ màn hình rộng",
                type: "switch",
            },
        ];
    }

    getSettingEditableValueView(item) {
        var value = this.props[item.key];
        if (item.type === 'select') {
            return <select name={item.key}>
                {Object.keys(item.options).map((optionKey) => {
                    var optionVal = item.options[optionKey];
                    return <option value={optionKey} {...(value === optionKey ? "selected" : "")}>{optionVal}</option>
                })}
            </select>
        }

        if (item.type === 'switch') {
            return <Switch
                checked={value}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#46db66"
                handleDiameter={28}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                height={24}
                width={48}
                onChange={() => this.props.changeSettingValue(item.key, !value)}
            />
        }

        return <input {...item} value={value} />
    }

    getSettingItemView() {
        return this.settingItems.map(item => {
            return <div className="setting-item">
                <div className="setting-icon">
                    {<item.icon />}
                </div>
                <div className="setting-title">
                    {item.title}
                </div>
                <div className="setting-value" >
                    {this.getSettingEditableValueView.call(this, item)}
                </div>
            </div>
        })
    }

    render() {
        return (
            <div className="setting-container">
                {this.getSettingItemView.call(this)}
            </div>
        )
    }
}
