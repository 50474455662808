import { action_types } from './model'
import { supported_data_type } from '../data-viewer/model'
import { changeMapView } from './action'

const initState = {
    tabIndex: 0,
    content: [
        {
            type: "map",
            typeName: "Bản đồ khu vực",
            description: "Công viên nghĩa trang tâm linh Lạc Hồng Viên",
            data: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6575.052020214117!2d105.44403301664215!3d20.905183407892277!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313442fd5694ce01%3A0x8d5f483e90453e15!2zTmdoxKlhIHRyYW5nIEzhuqFjIEjhu5NuZyBWacOqbg!5e1!3m2!1svi!2s!4v1583386086600!5m2!1svi!2s" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=`,
            extended: {
                title: "Chi nhánh",
                type: supported_data_type.LIST,
                data: [
                    {
                        title: "Công ty Cổ phần Đầu tư Xây dựng & Thương mại Toàn Cầu",
                        description: "Số 683 Đ. Lạc Long Quân, Phú Thượng, Tây Hồ, Hà Nội",
                        data: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3728.226971635784!2d106.65930521445193!3d20.8628998987967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x32b4a96f4ad187c7!2sVinpearl%20Hotel%20Imperia%20Hai%20Phong!5e0!3m2!1sen!2s!4v1577968118857!5m2!1sen!2s" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=`,
                    },
                   
                ]
            }
        }
    ]
};

export default function handler(state = initState, actions) {
    if (actions.type == action_types.CHANGE_MAP_VIEW) {
        state.content[state.tabIndex] = {...state.content[state.tabIndex], ...actions.payload};
        return {...state};
    }
    if (actions.type == action_types.CHANGE_TAB) {
        return { ...state };
    }
    return state;
}