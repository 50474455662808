import {
    action_types
} from './model'
import {
    executeEvent
} from '../experience-viewer/action'

function isMobileDevice() {
    return window.innerWidth < 600;
}

export function selectItem(id, origin) {
    return function (dispatch) {
        var childItems = origin.filter(v => v.parent === id);
        var navItems = origin.filter(v => v.parent == null);
        if (childItems.length > 0) {
            navItems = childItems;
            dispatch({
                type: action_types.CHANGE_NEST_MODE,
                payload: true
            })
        }

        dispatch({
            type: action_types.CHANGE_SELECT_ITEM,
            payload: {
                id,
                navItems
            }
        });
        dispatch(executeEvent((krpano) => {
            krpano.call(`loadscene(${id},null,MERGE,BLEND(0.5));`);
        }));

        if (isMobileDevice()) {
            dispatch(changeNavStatus(true))
        }
    }
}

export function onBackClick() {
    return {
        type: action_types.CHANGE_NEST_MODE,
        payload: false
    }
}

export function changeNavStatus(isCollapse) {
    return {
        type: action_types.CHANGE_NAV_STATUS,
        payload: isCollapse
    }
}