import React, { Component } from 'react'
import { MdChevronLeft, MdChevronRight, MdDone, IoIosNavigate } from 'react-icons/all'
import './style.css'
import './style.mobile.css'

export default class NavigationBar extends Component {

    getNavScollingBarView() {
        var { navItems, currentItemId, selectItem } = this.props;
        if (currentItemId == null) return;
        var currentItemIndex = navItems.findIndex(v => v.id === currentItemId);
        var scrollingBarItems = [];
        scrollingBarItems.push(navItems[currentItemIndex - 1]);
        scrollingBarItems.push(navItems[currentItemIndex]);
        scrollingBarItems.push(navItems[currentItemIndex + 1]);

        scrollingBarItems = scrollingBarItems.filter(e => e != null);

        return scrollingBarItems.map(v => {
            var currentItemClass = currentItemId === v.id ? " current" : "";

            return <div className={"nav-scrolling-item" + currentItemClass}
                key={v.id}
                onClick={() => selectItem(v.id, navItems)}>
                {v.title}
            </div>
        })
    }

    getNavItemsView() {
        var { navItems, currentItemId, watchedItems, selectItem, isNestList, navSubItem } = this.props;
        var itemList = isNestList ? navSubItem : navItems.filter(v => v.parent == null);
        var navItemListView = itemList.map(v => {
            var isWatched = watchedItems.includes(v.id);
            var currentItemStatus = currentItemId === v.id ? " current" : "";

            return <div className={"nav-item" + currentItemStatus} key={v.id} onClick={() => selectItem(v.id, navItems)}>
                <div className="title">{v.title}</div>
                <div className="photo">
                    <img src={v.photo} alt={v.title} />
                </div>
                {isWatched ? <MdDone className="watched" /> : null}
            </div>
        });

        return <div className="nav-data">
            {navItemListView}
        </div>
    }

    getwatchedItemsView() {
        var { watchedItems, navItems, isFetching } = this.props;
        var watchedCount = navItems
            .filter((v) => watchedItems.includes(v.id))
            .length;
        var watchedStatus = `${watchedCount} / ${navItems.length}`;
        var deactiveLoadingStatus = isFetching ? "" : " deactive";

        return <div className="nav-status">
            <div className={"loading-status" + deactiveLoadingStatus}>
                <img src={process.env.PUBLIC_URL + "/resources/loading.gif"} />
            </div>
            <div className="watched-status">{watchedStatus}</div>
        </div>;
    }

    getNavBarBackground() {
        var { isCollapseNav, changeNavStatus } = this.props;
        return (
            <div className="nav-ui">
                <div className="nav-background"></div>
                <div className="nav-edge-btn" onClick={() => changeNavStatus(!isCollapseNav)}>
                    {isCollapseNav ? <IoIosNavigate /> : <MdChevronLeft />}
                </div>
            </div>
        )
    }

    getControllerNavItemView() {
        var { selectItem, currentItemId, navItems } = this.props;
        var jumpToNextItem = function () {
            var currentItemIndex = navItems.findIndex(v => v.id === currentItemId);
            var nextItem = navItems[currentItemIndex + 1];
            if (nextItem == null) return;
            selectItem(nextItem.id, navItems);
        }
        var jumpToPrevItem = function () {
            var currentItemIndex = navItems.findIndex(v => v.id === currentItemId);
            var prevItem = navItems[currentItemIndex - 1];
            if (prevItem == null) return;
            selectItem(prevItem.id, navItems);
        }
        return <div className="nav-item-controller">
            <MdChevronLeft id="prev-item-btn"
                onClick={jumpToPrevItem} />
            <MdChevronRight id="next-item-btn"
                onClick={jumpToNextItem} />
        </div>
    }

    render() {
        var classStatus = this.props.isCollapseNav ? " nav-status-collapse" : "";
        var menuStatusSettingHidden = this.props.setting_isWidescreenOn & this.props.isCollapseNav ? " setting-autowide" : "";
        var backBtnStatus = this.props.isNestList ? "" : " disable-back";

        return (
            <div className={"navigator" + classStatus + menuStatusSettingHidden}>
                <div className="navigation-scolling-bar">
                    {this.getNavScollingBarView.call(this)}
                </div>
                <div className="navigation-bar">
                    <h2 className="nav-title">
                        <MdChevronLeft className={"back-icon" + backBtnStatus} onClick={this.props.onBackClick} />
                        <span>{this.props.titleName}</span>
                    </h2>
                    {this.getControllerNavItemView.call(this)}
                    {this.getNavBarBackground.call(this)}
                    {this.getNavItemsView.call(this)}
                    {this.getwatchedItemsView.call(this)}
                </div>
            </div>
        )
    }
}
