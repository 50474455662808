import {ObjectModel, FunctionModel, Any} from 'objectmodel'

export const action_types = {
    CHANGE_SETTING_VALUE : "change_setting_value"
}

export const EventModel = new ObjectModel({
    changeSettingValue: FunctionModel(Any),
    applySetting: FunctionModel(String, Any)
});

export const SettingModel = new ObjectModel({

});