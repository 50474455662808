import React, { Component } from 'react'
import './App.css'
import NavigationBar from '../../components/navigator/container'
import ExperiencePlace from "../../components/experience-viewer/container"
import Menubar from "../../components/menubar/container"
import Toolbar from "../../components/toolbar/container"
import Popup from "../../components/popup/container"
import Guide from "./guide"
import Introduction from '../../components/introduction'
import storage from '../../store'
import { initState } from './reducer'
import Fullscreen from "react-full-screen";


class App extends Component {
	constructor() {
		super();
		this.state = {
			isToggleFull: false,
			hiddenFullscreenBtn: false
		};
	}

	init() {
		initState.isReady = true;
		storage.dispatch({
			type: "app",
			payload: initState
		});
		return (
			<div className="app-mask">
				<Guide />
				<Popup />
			</div>
		)
	}

	getAppView() {
		return (
			<div className="App">
				<Introduction skip={false} onIntroComplete={this.init.bind(this)} />
				<div id="controller-layer">
					<NavigationBar />
					<Menubar />
					<Toolbar />
				</div>
				<div id="experience-layer">
					<ExperiencePlace />
				</div>
			</div>
		);
	}

	componentDidMount(){
		setTimeout(()=>{
			this.setState({hiddenFullscreenBtn: true});
		}, 10000);
	}

	render() {
		var fullsceneBtnStatus = this.state.isToggleFull || this.state.hiddenFullscreenBtn ? " hidden-btn" : "";
		return (<Fullscreen
			enabled={this.state.isToggleFull}
		>
			<div className={"full-screen-btn" + fullsceneBtnStatus}
				onClick={() => { this.setState({ isToggleFull: true }) }}>
				{"Fullscreen"}</div>
			{this.getAppView()}
		</Fullscreen>)
	}
}

export default App;
