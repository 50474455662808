import React, { Component } from 'react'
import { IoMdShare, FaFacebookSquare, FaTwitterSquare } from 'react-icons/all'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import './style.css'


export default class ShareTool extends Component {

    render() {
        var url = "https://vsmap.vn";
        return (
            <div className="tool-item" id="tool-share">
                <div className="item-icon">
                    <IoMdShare />
                </div>
                <div className="item-widget">
                    <div className="share-type-container">
                        <FacebookShareButton url={url}>
                            <FaFacebookSquare />
                        </FacebookShareButton>
                        <TwitterShareButton url={url}>
                            <FaTwitterSquare />
                        </TwitterShareButton>
                    </div>
                </div>
            </div>

        )
    }
}
