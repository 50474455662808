import { connect } from 'react-redux';
import view from './view';
import {changeSettingValue, applySetting} from './action';

const stateToProps = (state) => {
    return state.setting
};
const actionToProps = {
    changeSettingValue,
    applySetting
};

export default connect(stateToProps, actionToProps)(view);