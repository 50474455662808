import { connect } from 'react-redux';
import view from './view';
import {changeMapView} from './action';

const stateToProps = (state) => {
    return state.map
};
const actionToProps = {
    changeMapView
};

export default connect(stateToProps, actionToProps)(view);