import { connect } from 'react-redux'
import view from './view'
import { openUrl, openSetting, setMenuStatus, goHomePage } from './action'
import { EventModel } from './model'

const stateToProps = (state) => {
    return {
        ...state.menu,
        nav_isCollapseNav: state.nav.isCollapseNav,
        setting_isWidescreenOn: state.setting.isWidescreenOn
    }
};
const actionToProps = new EventModel({ openUrl, openSetting, setMenuStatus, goHomePage });

export default connect(stateToProps, actionToProps)(view);