import React, { Component } from 'react'
import { MdClose } from 'react-icons/md'
import { popup_type, supported_data_type } from './model'
import DataViewer from '../data-viewer/container'
import './style.css'
import './style.frame_dark.css'
import './style.mobile.css'

import MapViewer from '../map/container'

export default class Popup extends Component {
    componentWillMount() {
        // this.props.loadContent(popup_type.WINDOW, {
        //     title: "test md",
        //     dataType: supported_data_type.MARKDOWN,
        //     data: require("../data-viewer/test/demo2.md"),
        // });
        // this.props.loadContentByUrlAsync(popup_type.WINDOW, require("../data-viewer/test/demo2.md"), "test md");
    }

    getPopupContentByType() {
        var { popupType, title, data, dataType, hiddenPopup } = this.props;
        if (popupType == popup_type.NONE) return;
        if (popupType === popup_type.FRAME) {
            return <div className="frame-popup">
                <div className="title">
                    {title}
                </div>
                <div className="close-btn" onClick={hiddenPopup}>
                    <MdClose />
                </div>
                <div className="frame-content">
                    <DataViewer dataType={dataType} data={data} />
                </div>
            </div>
        }
        if (popupType === popup_type.WINDOW) {
            return <div className="window-popup">
                <div className="close-btn" onClick={hiddenPopup}>
                    <MdClose />
                </div>
                <div className="window-content">
                    <iframe src={data} />
                </div>
            </div>
        }
    }

    render() {
        var popupContent = this.getPopupContentByType.call(this);
        var exitPopupStatus = this.props.isHidden || popupContent == null ? " hidden" : "";
        return (
            <div className={"popup" + exitPopupStatus}>
                <div className="popup-background" onClick={this.props.hiddenPopup}>
                </div>
                {popupContent}
            </div>
        )
    }
}
