import React, { Component } from 'react'
import storage from '../../../../store'
import { MdOpenInNew, FaMapMarkedAlt } from 'react-icons/all'
import { popup_type, supported_data_type } from '../../../popup/model'
import { loadContent } from '../../../popup/action'
import MapViewer from '../../../map/container'
import './style.css'

export default class MapTool extends Component {
    constructor() {
        super();
        this.state = {
            playStatus: false
        };
    }

    openMap() {
        storage.dispatch(loadContent(popup_type.FRAME, {
            title: "Bản đồ chi tiết",
            dataType: supported_data_type.ELEMENT,
            data: MapViewer,
        }));
    }

    isMobileMode() {
        return window.innerWidth < 600;
    }

    render() {
        var { setting_isWidescreenOn, nav_isCollapseNav } = this.props;
        var menuStatusSettingHidden = setting_isWidescreenOn & nav_isCollapseNav ? " setting-autowide" : "";

        return <div className={"map-tools" + menuStatusSettingHidden} id="tool-map">
            <div className="map-btn" onClick={this.openMap.bind(this)}>
                <FaMapMarkedAlt />
            </div>
            <div className="map-preview">
                <MapViewer minimize={true} />
            </div>
        </div>
    }
}
