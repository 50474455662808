import { ObjectModel, ArrayModel } from 'objectmodel'

export const supported_data_type = {
    MARKDOWN: ".md",
    ELEMENT: "element",
    HTML: ".html",
    // TEXT: ".txt",
    IMAGES: "images",
    LIST: "list",
}

export const ImageItemModel = new ObjectModel({
    src: String,
    onClick: [Function]
});

export const ImageListModel = new ArrayModel([ImageItemModel]);

const DataList = new ArrayModel([String, Function]);

export const DataViewerModel = new ObjectModel({
    dataType: [...Object.values(supported_data_type), undefined],
    data: [String, Object, DataList, undefined]
});