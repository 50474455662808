import { ObjectModel, FunctionModel, ArrayModel } from 'objectmodel'

export const action_types = {
    CHANGE_SELECT_ITEM: 'change_select_item',
    CHANGE_NAV_STATUS: 'change_nav_status',
    CHANGE_NEST_MODE: 'change_nest_mode'
}

export const NavItemModel = new ObjectModel({
    id: String,
    title: [String],
    photo: String,
    parent: [String]
});

export const EventModel = new ObjectModel({
    /** selectItem(id) : Object */
    selectItem: FunctionModel(String).return(Object),
    /** changeNavStatus(isCollapse) : Object */
    changeNavStatus: FunctionModel(Boolean).return(Object),

    onBackClick: FunctionModel().return(Object)
});

export const NavigationModel = new ObjectModel({
    isCollapseNav: Boolean,
    isFetching: Boolean,
    currentItemId: [String, Number],
    navItems: [new ArrayModel(NavItemModel)],
    titleName: [String],
    watchedItems: new ArrayModel(String),
    isNestList: [Boolean],
    navSubItem: [new ArrayModel(NavItemModel)]
})
    .defaultTo({
        currentItemId: -1,
        isCollapseNav: true,
        isFetching: false,
        navItems: [],
        navSubItem: [],
        watchedItems: []
    });