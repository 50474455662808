import React, { Component } from 'react'
import Tour from "reactour"
import storage from '../../store'

export default class Guide extends Component {
    constructor() {
        super();
        var isWatchedGuide = localStorage.getItem("is_watched_guide");
        this.state = {
            isOpen: isWatchedGuide == null ? true : !isWatchedGuide
        };
    }
    get steps() {
        var steps = [];
        var isSimpleMode = storage.getState().setting.isWidescreenOn;
        var isMobileMode = window.innerWidth < 600;
        steps.push({
            content: 'Chào mừng bạn đến với trải nghiệm không gian Lạc Hồng Viên. Click để xem hướng dẫn sử dụng',
            position: 'center'
        });
        if (!isSimpleMode) {
            steps.push({
                selector: isMobileMode ? '.menu-collapse-btn' : '.menu-bar',
                content: 'Thanh Menu sẽ giúp bạn chuyển đến website liên kết từ nhà cung cấp',
                position: 'center'
            });
        }
        if (!isMobileMode) {
            steps.push({
                selector: '.setting-btn',
                content: 'Nút Cài Đặt chứa các chức năng mở rộng của chương trình',
                position: 'center'
            });
        }
        steps.push({
            selector: isMobileMode ? '.nav-item-controller' : '.navigation-scolling-bar',
            content: 'Thanh cuộn chuyển hướng sẽ giúp bạn di chuyển giữa các cảnh liên tiếp',
            position: 'center',

        });
        steps.push({
            selector: '.nav-edge-btn',
            content: 'Thanh Điều Hướng sẽ giúp bạn di chuyển nhanh chóng giữa các cảnh',
            position: 'center',
        });
        if (!isSimpleMode) {
            steps.push({
                selector: '.control-tools',
                content: 'Thanh Chức Năng sẽ hỗ trở bạn trong quá trình sử dụng',
                position: 'center',
            });
            steps.push({
                selector: '#tool-play',
                content: 'Nút Tự Động Chạy, dẫn bạn đi tham quan một cách tự động',
                position: 'center',
            });
            steps.push({
                selector: '#tool-search',
                content: 'Nút Tìm Kiếm, cho phép bạn tìm kiếm địa điểm theo thông tin',
                position: 'center',
            });
            steps.push({
                selector: '#tool-info',
                content: 'Nút Home, giúp bạn quay lại cảnh đầu tiên',
                position: 'center',
            });
            steps.push({
                selector: '#tool-share',
                content: 'Nút Chia Sẻ, giúp bạn chia sẻ thông tin địa điểm này đến bạn bè',
                position: 'center',
            });
            steps.push({
                selector: '#tool-music',
                content: 'Nút Âm Thanh, cho phép bạn bật/tắt nhạc nền, lời thoại',
                position: 'center',
            });

        }
        if (!isSimpleMode && !isMobileMode) {
            steps.push({
                selector: '#tool-map',
                content: 'Nút Bản Đồ, cho phép bạn xem bản đồ về vị trí',
                position: 'center',
            });
        }

        steps.push({
            content: 'Chúc bạn có một trải nghiệm vui vẻ :)',
            action: node => {
                setTimeout(() => this.closeTour.call(this), 3000);
            }
        });

        return steps;
    }

    closeTour() {
        this.setState({ isOpen: false });
        localStorage.setItem("is_watched_guide", true)
    }

    render() {
        return (
            <div>
                <Tour
                    steps={this.steps}
                    maskSpace={3}
                    rounded={3}
                    isOpen={this.state.isOpen}
                    scrollDuration={10}
                    onRequestClose={this.closeTour.bind(this)} />
            </div>
        )
    }
}
