import { ObjectModel, FunctionModel } from 'objectmodel'
import { DataViewerModel } from "../data-viewer/model"

export { supported_data_type as supported_data_type } from "../data-viewer/model"

export const popup_type = {
    FRAME: "frame",
    // DIALOG: "dialog",
    WINDOW: "window",
    NONE: "none"
};

export const action_types = {
    LOAD_POPUP_CONTENT: 'load_popup_content',
    EXIT: 'exit',
    TRIGGER_UPDATE_POPUP: 'trigger_update_popup'
};

export const EventModel = new ObjectModel({
    /** hiddenPopup() : PopupModel */
    hiddenPopup: FunctionModel().return(Object),
    /** loadContentByUrlAsync(popupType : String, path : String, title : String) : PopupModel */
    loadContentByUrlAsync: FunctionModel(String, String, [String], [String]).return(Object),
    /** loadContent(popupType, data) : PopupModel */
    loadContent: FunctionModel(String, DataViewerModel).return(Object)
});

export const PopupModel = new ObjectModel({
    isHidden: [Boolean],
    title: [String],
    popupType: Object.values(popup_type),
})
    .extend(DataViewerModel)
    .defaultTo({
        isHidden: true,
        popupType: popup_type.NONE,
    });