import { action_types } from './model'
import { action_types as popup_action_types } from '../popup/model'

export function changeMapView(item) {
    return function (dispatch) {
        dispatch({
            type: action_types.CHANGE_MAP_VIEW,
            payload: {
                data: item.data,
                description: item.description
            }
        });
        dispatch({
            type: popup_action_types.TRIGGER_UPDATE_POPUP
        });
    }
}