import React, { Component } from 'react'
import storage from '../../../../store'
import { IoMdPause, IoMdPlay } from 'react-icons/io'
import { executeEvent } from '../../../experience-viewer/action'
import './style.css'

export default class PlayTool extends Component {
    constructor() {
        super();
        this.state = {
            playStatus: false
        };
    }

    togglePlay() {
        if (this.state.playStatus) {
            storage.dispatch(executeEvent((krpano) => {
                krpano.call("autotour_stop();");
            }));
        } else {
            storage.dispatch(executeEvent((krpano) => {
                krpano.call("autotour_start();");
            }));
        }

        this.setState({ playStatus: !this.state.playStatus });

    }
    render() {
        return (
            <div className="tool-item" id="tool-play">
                <div className="item-icon" onClick={this.togglePlay.bind(this)}>
                    {
                        this.state.playStatus ? <IoMdPause /> : <IoMdPlay />
                    }
                </div>
            </div>
        )
    }
}
