import { connect } from 'react-redux';
import view from './view';
import { EventModel } from './model'

const stateToProps = (state) => {
    var sceneData;
    var sceneDetail;
    try {
        var experienceContent = state.experience.content;
        sceneData = experienceContent.scene;
        var sceneDetail = sceneData
            .filter(e => e.name == experienceContent.currentSceneName)[0]
            .detail
            .trim();
    } catch (err) { console.log("can't load scene detail", err) }
    return {
        ...state.tools,
        nav_isCollapseNav: state.nav.isCollapseNav,
        setting_isWidescreenOn: state.setting.isWidescreenOn,
        experience_sceneDetail: sceneDetail,
        experience_scene: sceneData,
        app_isReady: state.app.isReady
    }
};

const actionToProps = new EventModel({ });

export default connect(stateToProps, actionToProps)(view);