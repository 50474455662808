import React, { Component } from 'react'
import MapViewer from '../map/container'
import ShareTool from './tool-item/share'
import SearchTool from './tool-item/search'
import PlayTool from './tool-item/play'
import MusicTool from './tool-item/music'
import InfoTool from './tool-item/info'
import HomeTool from './tool-item/home'
import MapTool from './tool-item/map'
import "./style.css"
import "./style.mobile.css"


export default class Toolbar extends Component {

    getControlToolView() {
        return [
            <PlayTool />,
            <SearchTool dataset={this.props.experience_scene} />,
            // <InfoTool textMd={this.props.experience_sceneDetail} />,
            <HomeTool/>,
            <ShareTool />,
            <MusicTool play={this.props.app_isReady}/>,
            <MapTool {...this.props} />
        ]
    }

    render() {
        var { nav_isCollapseNav, setting_isWidescreenOn } = this.props;
        var navStatusExpand = nav_isCollapseNav ? "" : " nav-status-expand";
        var menuStatusSettingHidden = setting_isWidescreenOn & nav_isCollapseNav ? " setting-autowide" : "";

        return (
            <div className={"toolbar-place" + navStatusExpand + menuStatusSettingHidden}>
                <div className="control-tools">
                    {this.getControlToolView.call(this)}
                </div>
            </div>
        )
    }
}
