import {combineReducers} from 'redux'
import NavReducer from './components/navigator/reducer'
import ExperienceViewerReducer from './components/experience-viewer/reducer'
import MenubarReducer from './components/menubar/reducer'
import ToolbarReducer from './components/toolbar/reducer'
import PopupReducer from './components/popup/reducer'
import MapReducer from './components/map/reducer'
import SettingReducer from './components/setting/reducer'
import AppReducer from './view/homepage/reducer'


const rootReducer = combineReducers({
    nav: NavReducer,
    menu: MenubarReducer,
    tools: ToolbarReducer,
    experience: ExperienceViewerReducer,
    popup: PopupReducer,
    map: MapReducer,
    setting: SettingReducer,
    app: AppReducer
});

export default rootReducer;
