import { action_types, MenubarModel } from './model'

const initState = {
    logo: "/data/vtour/image/logo.png",
    menuItems: [
        {
            id: "about_us",
            title: "Về chúng tôi",
            url: "https://lachongvien.vn/gioi-thieu/gioi-thieu-nghia-trang-lac-hong-vien"
        },
        {
            id: "product",
            title: "Tham Quan Đồi Chính ",
           
            subMenu: [
                { id: "scan3d-i", title: "Tham Quan Đồi Kim",
                
                	url:"https://media.lachongvien.com.vn/duongdidoikim/"
                },
                { id: "scan3d-i", title: "Tham Quan Đồi Kim Quy",
                
                	url:"https://media.lachongvien.com.vn/duongdikimquy/"
                },
                { id: "scan3d-i", title: "Tham Quan Đồi Mộc",
                
                	url:"https://media.lachongvien.com.vn/doimoc/"
                },
                { id: "scan3d-i", title: "Tham Quan Đồi Hỏa",
                
                	url:"https://media.lachongvien.com.vn/doihoa/"
                },
                
               
            ]
        },
        {
            id: "term",
            title: "Cúng giỗ Online",
            url: "https://lachongvien.vn/cung-gio-online"
        },
        {
            id: "blog",
            title: "Hướng phong thủy",
            url: "https://lachongvien.vn/vrmaps/map/"
        },
        {
            id: "book",
            title: "Liên hệ",
            url: "https://lachongvien.vn/#lienhe-scroll",
        }
    ]
};

export default function handler(state = new MenubarModel(initState), actions) {
    if (actions.type === action_types.SET_STATUS) {
        return new MenubarModel({
            ...state,
            isExpandMenu: actions.payload
        });
    }
    return state;
}