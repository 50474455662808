import { action_types } from './model'
import { action_types as popup_action_types } from '../popup/model'
import { executeEvent } from '../experience-viewer/action'

export function changeSettingValue(key, value) {
    var payload = {};
    payload[key] = value;
    return function (dispatch) {
        dispatch({
            type: action_types.CHANGE_SETTING_VALUE,
            payload
        });
        var settingEvent = applySetting(key, value);
        if(settingEvent) dispatch(settingEvent);
        dispatch({
            type: popup_action_types.TRIGGER_UPDATE_POPUP
        });
        localStorage.setItem(key, value);
    }
}

export function applySetting(key, value) {
    if (key === "isAutoRotate") {
        return executeEvent((krpano) => {
            krpano.set("autorotate.enabled", value);
        })
    }

    if (key === "isGyroOn") {
        return executeEvent((krpano) => {
            krpano.set("plugin[gyro].enabled", value);
        })
    }

}