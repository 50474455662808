import { action_types, ExperienceViewerModel } from './model'

export default function handler(state = new ExperienceViewerModel(), actions) {
    if (actions.type === action_types.SET_CONTROLLER_DRIVER) {
        return new ExperienceViewerModel(actions.payload)
    }
    if(actions.type === action_types.EXECUTE_EVENT){
        return new ExperienceViewerModel(state)
    }
    return state;
}