import React, { Component } from 'react'

export default class Krpano extends Component {

    onKrpanoReady(krpano) {
        var onDriverLoaded = this.props.onDriverLoaded;
        krpano.set("events.onloadcomplete", function () {
            onDriverLoaded(krpano)
        });
    }

    scriptLoaded() {
        window.embedpano({
            swf: "/data/vtour/tour.swf",
            xml: "/data/vtour/tour.xml",
            target: "pano",
            html5: "auto",
            mobilescale: 1.0,
            passQueryParameters: true,
            onready: this.onKrpanoReady.bind(this)
        });

    }

    componentWillMount() {
        const script = document.createElement("script");
        script.src = "/data/vtour/tour.js";
        script.async = true;
        script.onload = () => this.scriptLoaded.call(this);

        document.body.appendChild(script);
    }

    render() {
        return <div id='pano'></div>
    }
}
