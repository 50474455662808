import React, { Component } from 'react'
import { MdHome } from 'react-icons/all'
import storage from '../../../../store'
import { executeEvent } from '../../../experience-viewer/action'


export default class HomeTool extends Component {
    constructor() {
        super();
        this.state = {
            toggleDetail: false
        }
    }

    goHomePage() {
        	storage.dispatch(executeEvent((krpano) => {
            	krpano.call("startup()");
        	}));
	}

    render() {
        return (
            <div className="tool-item" id="tool-home">
                <div className="item-icon" onClick={this.goHomePage.bind(this)}>
                    <MdHome />
                </div>
                {
                    this.state.toggleDetail ? this.getInfoPanelView.call(this) : null
                }
            </div>
        )
    }
}
