import React, { Component } from 'react'
import { FiSearch } from 'react-icons/fi'
import { GoSearch } from 'react-icons/go'
import store from '../../../../store'
import { executeEvent } from '../../../experience-viewer/action'
import FuseSearch from 'fuse.js'
import './style.css'


export default class SearchTool extends Component {
    constructor() {
        super();
        this.state = {
            toggleSearchBox: false,
            searchResult: []
        }
    }

    componentDidUpdate() {
        var options = {
            shouldSort: true,
            tokenize: true,
            threshold: 0.4,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 2,
            keys: ['name', 'title']
        };
        this.searcheEngine = new FuseSearch(this.props.dataset, options);
    }

    onSearchClick() {
        this.setState({ toggleSearchBox: !this.state.toggleSearchBox }, () => {
            this.refs.inputSearch.focus();
        });
    }

    doSearch() {
        var input = this.refs.inputSearch.value;
        var searchResult = this.searcheEngine.search(input);
        this.setState({ searchResult });
        // console.log("search result", searchResult);
    }

    onResultItemClick(item) {
        // console.log("item", item);
        this.setState({ toggleSearchBox: false });
        store.dispatch(executeEvent((krpano) => {
            krpano.call(`loadscene(${item.name},null,MERGE,BLEND(0.5));`);
        }))
    }

    getSearchResultView(){
        return this.state.searchResult.map((v) => {
            // console.log("search result", v);
            return <div className="search-result-item"
                onClick={() => this.onResultItemClick.call(this, v)}>
                <div className="item-thumb">
                    <img src={v.thumburl} />
                </div>
                <div className="item-title">
                    {v.title}
                </div>
            </div>
        })
    }

    render() {
        var searchBoxStatusToggle = !this.state.toggleSearchBox ? " toggle-search-off" : "";
        return (
            <div className={"search-item-container tool-item" + searchBoxStatusToggle} id="tool-search">
                <div className="item-icon" onClick={this.onSearchClick.bind(this)}>
                    <FiSearch />
                </div>
                <div className="search-box">
                    <div className="input-search">
                        <input ref="inputSearch"
                            onChange={this.doSearch.bind(this)}
                            placeholder="Nhập để tìm kiếm ..." />
                        <GoSearch className="input-search-icon" />
                    </div>
                    <div className="search-result-container">
                        {this.getSearchResultView()}
                    </div>
                </div>
            </div>
        )
    }
}
