import { action_types } from './model'
import { xml2js } from 'xml-js'

var providerInterface;

function getCachedDataByVersion(version = 0, getDefaultValue) {
    var cachedData = localStorage.getItem("experience-data");
    if (cachedData == null || cachedData.content == null || cachedData.version != version) {
        var content = getDefaultValue();
        localStorage.setItem("experience-data", { version, content });
        return content;
    } else return cachedData.content;
}

function extractKrpanoData() {
    var dataVersion = providerInterface.get("xmlelement[data-version].value");
    return getCachedDataByVersion(dataVersion, () => {
        var scene = providerInterface.get("scene").getArray();

        var currentSceneName = providerInterface.get("xml.scene");
        var experienceData = {
            provider_name: 'krpano',
            content: {
                currentSceneName,
                scene: scene.map((v) => {
                    var sceneContent = xml2js(`<xml>${v.content}</xml>`, {
                        compact: true,
                        ignoreComment: true,
                        ignoreCdata: true
                    }).xml;
                    return {
                        index: v.index,
                        name: v.name,
                        title: v.title,
                        thumburl: v.thumburl,
                        parent: v.parent,
                        detail: sceneContent.detail ? sceneContent.detail._text : ""
                    };
                })
            }
        };

        return {
            type: action_types.SET_CONTROLLER_DRIVER,
            payload: experienceData
        }
    });
}

export function onDriverLoaded(provider) {
    providerInterface = provider;
    return extractKrpanoData();
}

export function executeEvent(event) {
    return {
        type: action_types.EXECUTE_EVENT,
        payload: event(providerInterface)
    }

}