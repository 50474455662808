import { action_types } from './model'
import { changeNavStatus } from '../navigator/action'
import { loadContent } from '../popup/action'
import { popup_type, supported_data_type } from '../popup/model'
import Setting from '../setting/container'
import { executeEvent } from '../experience-viewer/action'

export function openUrl(url) {
        return function (dispatch) {
            if(url == null) return;
            dispatch({
                type: action_types.CHOOSE_ITEM,
                payload: { url }
            });

            dispatch(loadContent(popup_type.WINDOW, {
                data: url,
            }));
        }
}

export function openSetting() {
    return function (dispatch) {
        dispatch({
            type: action_types.CHOOSE_ITEM,
            payload: "setting"
        });

        dispatch(loadContent(popup_type.FRAME, {
            title: "Cài đặt",
            dataType: supported_data_type.ELEMENT,
            data: Setting,
        }));
    }
}

export function setMenuStatus(isHidden) {
    return function (dispatch) {
        dispatch({
            type: action_types.SET_STATUS,
            payload: isHidden
        });
        dispatch(changeNavStatus(true));
    }
}

export function goHomePage() {
    return function (dispatch) {
        dispatch(executeEvent((krpano) => {
            krpano.call("startup()");
        }));
    }
}