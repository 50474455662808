import React, { Component } from 'react'
import "./style.css"
import { AiFillInfoCircle, FiInfo } from 'react-icons/all'
import ReactMardown from 'react-markdown'
import thunk from 'redux-thunk';

export default class InfoTool extends Component {
    constructor() {
        super();
        this.state = {
            toggleDetail: false
        }
    }

    getInfoPanelView() {
        return <div className="info-panel">
            {this.props.textMd ? <ReactMardown
                className="info-panel-content"
                source={this.props.textMd}
            /> : null}
        </div>
    }

    render() {
        return (
            <div className="info-type-container tool-item" id="tool-info">
                <div className="item-icon" onClick={() => {
                    this.setState({ toggleDetail: !this.state.toggleDetail })
                }}>
                    {this.state.toggleDetail ? <AiFillInfoCircle /> : <FiInfo />}
                </div>
                {
                    this.state.toggleDetail ? this.getInfoPanelView.call(this) : null
                }
            </div>
        )
    }
}
