import axios from 'axios'
import { action_types, popup_type, supported_data_type, PopupModel } from './model'

export function loadContent(popupType, content) {
    return {
        type: action_types.LOAD_POPUP_CONTENT,
        payload: new PopupModel({
            title: content.title,
            popupType,
            dataType: content.dataType,
            data: content.data,
            isHidden: false
        })
    }
}

export function loadContentByUrlAsync(popupType, path, title = null, dataType) {
    return function (dispatch) {
        axios.get(path).then(e => {
            dispatch(loadContent(popupType, {
                title,
                dataType,
                data: e.data,
            }));
        }).catch(err => {
            console.error(err);
        })
    }
}

export function hiddenPopup() {
    return {
        type: action_types.EXIT,
        payload: {
            popupType: popup_type.NONE,
            isHidden: true
        }
    }
}