import { createStore, applyMiddleware, compose } from "redux"
import rootReducer from "./reducer"
import thunk from 'redux-thunk'

export default createStore(
    rootReducer,
    {},
    compose.call(null, 
        applyMiddleware(thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    )
);
