import { connect } from 'react-redux';
import view from './view';
import { changeNavStatus, selectItem, onBackClick } from './action';
import { EventModel } from './model'

const stateToProps = (state) => {
    var experienceContent = state.experience.content || {};
    var sceneList = experienceContent.scene || [];
    var navItems = sceneList
        // .filter(v => v.parent == null)
        .map(v => {
            return {
                _index: v.index,
                id: v.name,
                title: v.title,
                parent: v.parent,
                photo: v.thumburl,
            }
        });

    var currentItemId;
    if (state.experience.content != null) {
        var currentItemId = state.experience.content.currentSceneName;
        if(!state.nav.watchedItems.includes(currentItemId))
            state.nav.watchedItems.push(currentItemId);
    }

    return {
        ...state.nav,
        currentItemId,
        setting_isWidescreenOn: state.setting.isWidescreenOn,
        navItems
    }
};
const actionToProps = new EventModel({
    changeNavStatus,
    selectItem,
    onBackClick
});

export default connect(stateToProps, actionToProps)(view);