import { ObjectModel, FunctionModel, Any } from 'objectmodel'

export const action_types = {
    SET_CONTROLLER_DRIVER: "set_controller_driver",
    EXECUTE_EVENT: 'execute_event'
}

export const EventModel = new ObjectModel({
    onDriverLoaded: FunctionModel(Any).return(Object),
    executeEvent: FunctionModel(String).return(Object)
});

export const ExperienceViewerModel = new ObjectModel({
    provider_name: [String],
    content: [Object]
});