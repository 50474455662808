import { ObjectModel, FunctionModel, ArrayModel } from 'objectmodel'

export const action_types = {
    CHOOSE_ITEM: "choose_item",
    SET_STATUS: "set_status"
}

const SubMenuItemModel = new ObjectModel({
    id: String,
    title: String,
    url: [String],
})

const MenuItemModel = new ObjectModel({
    id: String,
    title: String,
    link: [String],
    onClick: [Function],
    subMenu: [new ArrayModel(SubMenuItemModel), undefined]
});

export const EventModel = new ObjectModel({
    /** openUrl(id) */
    openUrl: FunctionModel([String]),
    /** openSetting() */
    openSetting: FunctionModel().return(Function),
    /** setMenuStatus(isHidden) */
    setMenuStatus: FunctionModel(Boolean).return(Function),
    /** goHomePage() */
    goHomePage: FunctionModel().return(Function),
});

export const MenubarModel = new ObjectModel({
    menuItems: [new ArrayModel(MenuItemModel)],
    logo: [String],
    isExpandMenu: [Boolean],
    nav_isCollapseNav: [Boolean],
    setting_isWidescreenOn: [Boolean]
}).defaultTo({
    isExpandMenu: false
});