import { ObjectModel, FunctionModel, Any } from 'objectmodel'

export const action_types = {
    OPEN_MAP: "open_map",
    EXECUTE_ACTION: "exec_action"
}

export const ControlItemModel = new ObjectModel({
    name: String,
    icon: Function,
});

export const EventModel = new ObjectModel({
});

export const ToolbarModel = new ObjectModel({
    nav_isCollapseNav: [Boolean],
    setting_isWidescreenOn: [Boolean],
    experience_sceneDetail: [String],
    experience_scene: [Any],
    app_isReady: [Boolean]
});