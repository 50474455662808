import React, { Component } from 'react'
import { FiMenu, MdClose, MdSettings } from 'react-icons/all'
import $ from "jquery";
import "./style.css"
import "./style.mobile.css"


export default class Menubar extends Component {

    getMenuItemsView(menuItems, openUrlEvent) {
        if (menuItems == null) return;
        return menuItems.map((v, i) => {
            return <div className="menu-item" key={v.id}>
                <div className="item-title" onClick={() => openUrlEvent(v.url)}>
                    <a href={v.link}>{v.title}</a>
                </div>
                {
                    v.subMenu == null ? "" : <div className="item-sub-menu">
                        {this.getMenuItemsView(v.subMenu, openUrlEvent)}
                    </div>
                }
            </div>
        })
    }

    detectOverlapMenu() {
        var logoBounding = this.refs.logo.getBoundingClientRect();
        var logoRightX = logoBounding.x + this.refs.logo.offsetWidth;
        var menuBounding = this.refs.menubar.getBoundingClientRect();
        var menuLeftX = menuBounding.x;
        if (logoRightX > menuLeftX) {
            // $('meta[name="viewport"]').prop('content', 'width=600');
        }
    }

    componentDidUpdate() {
        this.detectOverlapMenu.call(this);
    }

    render() {
        // this.props.openSetting();
        var { logo, menuItems, openUrl, goHomePage, isExpandMenu, setMenuStatus, nav_isCollapseNav, openSetting, setting_isWidescreenOn } = this.props;
        var menuMobileStatusHidden = isExpandMenu ? " menu-status-expand" : "";
        var navStatusCollapse = nav_isCollapseNav ? "" : " nav-status-expand";
        var menuStatusSettingHidden = setting_isWidescreenOn & nav_isCollapseNav ? " setting-autowide" : "";

        // if(!isCollapseNav && !isExpandMenu){
        //     setMenuStatus(!isExpandMenu);
        // }

        return (
            <div className={"menu-place" + menuMobileStatusHidden + navStatusCollapse + menuStatusSettingHidden}>
                <div className="logo" ref="logo">
                    <img src={logo} onLoad={() => this.forceUpdate()} onClick={goHomePage} />
                </div>
                <div className="menu-bar" ref="menubar">
                    {this.getMenuItemsView(menuItems, openUrl)}
                </div>
                <div className="menu-collapse-btn" onClick={() => setMenuStatus(!isExpandMenu)}>
                    {isExpandMenu? <MdClose/> : <FiMenu />}
                </div>
                <div className="setting-btn">
                    <MdSettings onClick={openSetting} />
                </div>
            </div >
        )
    }
}
