import { ObjectModel, ArrayModel, FunctionModel } from 'objectmodel'

export const action_types = {
    CHANGE_MAP_VIEW: "change_map_view",
    CHANGE_TAB: "change_map"
}

export const EmbededViewerModel = new ArrayModel({
    title: String,
    description: [String],
    data: [String]

});

export const EventModel = new ObjectModel({
    changeMapView: FunctionModel(EmbededViewerModel)
});

export const MapViewerModel = new ObjectModel({
    type: ['map'],
    typeName: [String],
    extended: {
        title: String,
        type: ['list'],
        data: new ArrayModel([EmbededViewerModel])
    }
})
    .extend(EmbededViewerModel);

export const MapCollectorModel = new ArrayModel(MapViewerModel);

export const MapModel = new ObjectModel({
    content: [MapCollectorModel],
    tabIndex: [Number]
})
.defaultTo({
    tabIndex: 0,
    content: []
});