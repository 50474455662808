import React, { Component } from 'react'
import Krpano from './driver/Krpano'

import "./style.css"

export default class ExperienceViewer extends Component {

    render() {
        return (
            <div className="experience-viewer">
                <Krpano onDriverLoaded={this.props.onDriverLoaded}/>
            </div>
        )
    }
}